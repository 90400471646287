<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-body" >
          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">基础信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">姓名</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.name }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">电话号码</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.phone }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">开户行</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.bankName }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">身份证号</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.idNo }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">银行卡号</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.bankNo }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">所在地区</span>
                      <span class="descriptions-item-content">
                        {{ farmer && [farmer.province, farmer.city, farmer.area].join('') }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">详细地址</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.address }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">结算信息</div>
            </div>
            <div class="descriptions-view">
              <div>
                <el-form :model="pageForm"
                         :rules="pageFormRules"
                         ref="formRef"
                         label-position="left"
                         label-width="120px">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item prop="files" required label="结算图片">
                        <el-upload accept="image/*"
                                   class="upload-demo"
                                   action=""
                                   :multiple="true"
                                   ref="filesImgUpload"
                                   list-type="picture-card"
                                   :file-list="imageList"
                                   :on-remove="(f, fl) => this.imageList = fl"
                                   :http-request="handlerUploadImage">
                          <i class="el-icon-plus"></i>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
          <div
              class="descriptions"
              style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">操作</div>
            </div>
            <div class="descriptions-view">
              <el-button @click="closePage">取消</el-button>
              <el-button type="primary" @click="submit">提交</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {fileUpload, getPayById, payApply} from "@/api/apis";
import {exportPicture, viewImages} from "@/utils/common";
import helper, {gridConnectionStatusMapper, statusMapper} from "@/utils/helper";
import {INTENTION_CONSUMPTION_STATE} from "@/utils/config";
export default {
  name: "Edit",
  components: {PageHeader},
  data(){
    return {
      getData: getPayById,
      id: '',
      metadata: {},

      farmer: null,
      workOrder: null,
      roofTypeModel: null,
      user: null,
      designData: {},
      INTENTION_CONSUMPTION_STATE,
      designDataFiles:[],

      // ---
      fileList: [],
      // 并网图片
      imageList: [],
      //
      pageForm: {
        // 结算图片
        files: [],
      },
      pageFormRules: {
        files: [
          { required: true, message: '请上传结算图片', },
        ],
      },
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getInfo()
  },
  methods: {
    gridConnectionStatusMapper,
    viewImages,
    statusMapper,
    /**
     * 关闭
     */
    closePage(){
      this.$router.replace('/admin/settlement')
    },
    /**
     * 提交
     */
    submit(){
      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          return false
        }

        // 参数
        const params = { ...this.pageForm }
        params.payId = this.id
        params.files = this.pageForm.files.join(',')

        // 提交
        const loading = this.$loading({ fullscreen: true })
        payApply(params).then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.closePage()
          }, 500)
        }).finally(() => {
          loading.close()
        })
      })
    },
    /**
     * 图片上传
     * @param e
     */
    handlerUploadImage(e){
      const loading = this.$loading({ fullscreen: true })
      fileUpload(e.file).then(res => {
        const name = ((res || '').split('/') || []).reverse()[0]
        this.imageList.push({
          name: name,
          url: helper.getSourceUri(res),
          value: res
        })
        this.pageForm.files.push(res)
      }).finally(() => {
        loading.close()
      })

      this.$refs.filesImgUpload.uploadFiles = this.$refs.filesImgUpload.uploadFiles.filter(iter => iter.status === 'success')
    },
    /**
     * 点击文件
     * @param file
     */
    onPreviewHandler(file){
      const { url, name } = file
      exportPicture(url, name)
    },
    /**
     *
     */
    showFileList(item){
      const filesStr = item?.files || ''
      const files = []
      filesStr.split(',').forEach(iter => {
        const name = ((iter || '').split('/') || []).reverse()[0]
        iter && files.push({
          name: name,
          url: helper.getSourceUri(iter),
          value: iter
        })
      })
      return files
    },
    /**
     * 获取数据
     */
    getInfo(){
      if (this.id) {
        this.getData(this.id).then(res => {
          this.metadata = res
          //
          this.farmer = this.metadata?.farmer
          this.workOrder = this.metadata?.workOrder
          this.roofTypeModel = this.metadata?.workOrder?.roofTypeModel
          this.user = this.metadata?.workOrder?.user
          this.designData = this.metadata?.designData

          this.designDataFiles = this.showFileList(this.metadata)

        })
      } else {
        this.$message.error('页面异常, 请返回重新进入')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>